<script>
import FormErrors from '../../util/form-errors'

export default {
  render() {
    return this.$scopedSlots.default({
      formErrors: this.formErrors
    })
  },

  data: () => ({
    formErrors: new FormErrors()
  }),

  methods: {
    update(newErrors) {
      this.formErrors = newErrors
    }
  }
}
</script>